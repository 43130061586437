.tableWrapper {
    width: 100%;

    .tableOptions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 16px;

        .btnGroup {
            width: auto;
            margin: 0;
        }

        form {
            width: auto !important;
        }

    }

    .tableContainer {
        width: 100%;
        max-height: 70vh;
        
        table-layout: fixed;

        overflow-x: auto;
        overflow-y: auto;

        table {
            width: 100%;
            border-radius: 16px 16px 0 0;

            th, td {
                padding: 8px 12px;

                text-align: left;

                transition: var(--transition-theme);

                .tableColumnContent {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 16px;

                    .tableOption {
                        width: 20px;
                        height: 20px;

                        svg {
                            cursor: pointer;

                            &:hover path {
                                fill: var(--color-text-p) !important;
                            }

                            path {
                                fill: var(--color-text-t) !important;

                                transition: var(--transition-theme);
                            }

                        }

                        &.status svg {

                            &:hover path {
                                fill: var(--color-status-t) !important;
                            }

                            path {
                                fill: var(--color-status-p) !important;
                            }

                        }

                    }

                }

            }

            thead {
                position: sticky;
                top: 0;

                background-color: var(--color-theme-p);

                z-index: 1;
                transition: var(--transition-theme);

                tr {

                    th {
                        padding: 12px;
    
                        color: var(--color-text-p-dm);
                        font-size: 14px;
                        font-weight: normal;

                        transition: var(--transition-theme);
    
                        &:first-of-type {
                            border-radius: 16px 0 0 0;
                        }
    
                        &:last-of-type {
                            border-radius: 0 16px 0 0;
                        }
    
                    }

                }

            }

            tbody {
                background-color: var(--color-layout-p);

                transition: var(--transition-theme);

                tr {
                    border-bottom: 1px solid var(--color-divider);

                    transition: var(--transition-default);

                    &.expandedRow {
                        background-color: var(--color-layout-t) !important;
                    }

                    td {
                        color: var(--color-text-t);

                        transition: var(--transition-theme);

                        &.center {
                            text-align: center;

                            .tableColumnContent {
                                justify-content: center;
                            }

                        }

                        .tableColumnContent {
                            font-size: 14px;

                            transition: var(--transition-theme);

                            &.status {
                                color: var(--color-status-p) !important;
                            }

                        }

                    }

                    &:hover {
                        background: var(--color-action-hover);
                    }
    
                    &.status td {
                        color: var(--color-status-p) !important;
                    }

                }

            }

        }

    }

    .tablePaginatorFooter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 4px 24px;
        border-radius: 0 0 16px 16px;
        background-color: var(--color-layout-p);

        transition: var(--transition-theme);
    
        .tablePaginatorSection {

            .tablePaginatorIndicator {
                color: var(--color-text-t);
                font-size: 12px;

                transition: var(--transition-theme);
            }

            .tablePaginatorControls {

                .tablePaginatorButton {
                    padding: 8px;
                    border-radius: 50%;

                    transition: var(--transition-default);

                    &:hover {
                        background-color: var(--color-action-hover);

                        & svg path {
                            fill: var(--color-text-p) !important;
                        }

                    }

                    svg path {
                        color: var(--color-text-t);
                    }

                }

            }
    
        }
    
    }

}

.sectionBox {

    .tableWrapper {

        thead th {
            border-radius: 0 !important;
        }

        .tablePaginatorFooter {
            border-radius: 0 0 6px 6px !important;
        }

    }

}

.dark-mode {

    .tableWrapper {

        tbody {
            background-color: var(--color-layout-s) !important;
        }

        .tablePaginatorFooter {
            background-color: var(--color-layout-t) !important;
        }

    }

}