.containerWrapper {
    display: flex;
    width: 100%;

    // Direction
    &.direction-column {
        flex-direction: column;
    }

    &.direction-row {
        flex-direction: row;
    }

    // Align
    &.align-start {
        align-items: flex-start;
    }

    &.align-end {
        align-items: flex-end;
    }

    &.align-center {
        align-items: center;
    }

    // Justify
    &.justify-start {
        justify-content: flex-start;
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-end {
        justify-content: flex-end;
    }

    &.justify-between {
        justify-content: space-between;
    }

    &.justify-around {
        justify-content: space-around;
    }

    &.justify-evenly {
        justify-content: space-evenly;
    }

    &.padding {
        padding: 16px;
    }

    &.margin {
        margin: 16px;
    }

}