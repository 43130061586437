#mainViagemAtivaLista {

    section {

        &:first-of-type {

            .sectionContainer {
                padding-bottom: 0 !important;
        
                .sectionBox {
                    width: 50% !important;
                    margin: 0;
                }
            
            }

        }

    }

    
    
}