main {
    transition: 0.5s ease-in-out;
}

#wrapper {

    &.default {

        main {
            height: calc(100vh - var(--layout-header-height));

            // &.sidebar-open {
            //     padding-left: var(--layout-sidebar-width);
            // }

            #mainContainer {
                height: 100%;
            }

        }

    }

    &.center {

        main {
            min-height: 100%;
        }

    }

}