.pageGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    gap: 16px;
    width: 100%;

    .sectionBox {
        margin-bottom: 0 !important;

        &:nth-of-type(1){
            grid-column: 1 / 5;
        }

        &:nth-of-type(2){
            grid-column: 1 / 3;
        }

        &:nth-of-type(3){
            grid-column: 1 / 3;
            grid-row: 3 / 4;
        }

        &:nth-of-type(4){
            grid-column: 3 / 5;
            grid-row: 2 / 4;
        }

        &:nth-of-type(5){
            grid-column: 1 / 2;
        }
        
        &:nth-of-type(8){
            grid-column: 4.5 / 5;
        }

        .rastreadorContainer {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            input {
                width: 80px;
            }

        }

    }

}

.formFooter {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin-top: 24px;
}