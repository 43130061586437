#pageTitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 24px;
    background-color: #DCDBEF;

    transition: var(--transition-default);

    .pageTitleTextContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;

        .goBackAction {
            width: 24px;
            height: 24px;

            font-size: 24px;
    
            cursor: pointer;

            &:hover {

                svg path {
                    fill: var(--color-theme-p-hover) !important;
                }

            }

            svg path {
                fill: var(--color-theme-p) !important;
    
                transition: var(--transition-default);
            }

        }

        h1 {
            color: var(--color-theme-p);
            font-size: 12px;
    
            transition: var(--transition-default);
        }

        .pageTitleContent {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

    }

}

.dark-mode {

    #pageTitleContainer {
        background-color: var(--color-theme-p-light);

        .pageTitleTextContainer {

            .goBackAction {

                &:hover {

                    svg path {
                        fill: var(--color-text-t) !important;
                    }

                }

                svg path {
                    fill: var(--color-text-p) !important;
                }

            }

            h1 {
                color: var(--color-text-p);
            
            }

        }
        

    }

}