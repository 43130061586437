.fileListWrapper {
    width: 100%;
    
    .fileList {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        margin-bottom: 24px;

        .fileListItem {
            display: flex;
            flex-direction: row;
            gap: 16px;
            
            width: 100%;
    
            .itemContainer {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
    
                &:nth-of-type(2){
                    flex: 1;
                }
    
                span {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 41px;
                    padding: 8px 16px;
                    border-radius: 10px;
                    background-color: var(--color-layout-p);
                    background-color: var(--color-disabled-t-lm);
                    border: 2px solid var(--color-divider);
        
                    color: var(--color-text-p);
                    font-size: 12px;
        
                    transition: var(--transition-theme);
                }
    
            }
    
        }

    }

}

.dark-mode {

    .fileListWrapper {

        .itemContainer {

            span {
                background-color: var(--color-disabled-s-dm) !important;
            }

        }

    }

}