.formGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
    width: 100%;

    .sectionBox {
        margin-bottom: 0 !important;

        &:nth-of-type(1) {
            grid-column: 1 / 5;
        }



        &:nth-of-type(6) {
            grid-column: 1 / 5;
        }

        .rastreadorContainer {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            input {
                width: 80px;
            }
        }

    }

}

.formFooter {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin-top: 24px;
}