.moduleIconSelectorContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    width: 100%;
    margin-top: 24px;

    .moduleIconSelectorBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border: 2px solid var(--color-divider);
        border-radius: 8px;

        cursor: pointer;
        transition: var(--transition-theme);

        :not(.typed){

            &:hover {
                border-color: var(--color-text-t);
    
                svg path {
                    fill: var(--color-text-t) !important;
                }
    
            }
            
        }

        &.active {
            border-color: var(--color-text-p);

            svg path {
                fill: var(--color-text-p) !important;
            }

        }

        &.typed {
            border-color: var(--color-theme-s) !important;

            svg path {
                fill: var(--color-theme-s) !important;
            }

        }

        svg {

            path {
                fill: var(--color-text-t) !important;

                transition: var(--transition-theme);
            }

        }

    }

}