.btnGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
    width: 100%;
    height: 100%;
}

.btnWrapper {

    a {
        border-radius: 8px;
    }

    .btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        height: auto;
        min-height: 41px;
        padding: 8px 16px;
        border: 1px solid var(--color-theme-p);
        border-radius: 10px;

        font-size: 12px;
        font-weight: 400 !important;
        text-transform: none !important;
    
        cursor: pointer;
        transition: var(--transition-default);
    
        &.default {
            background-color: var(--color-theme-p);
    
            color: var(--color-text-p-dm);
    
            &:hover {
                border-color: var(--color-theme-p-dark);
                background-color: var(--color-theme-p-dark);
            }

            svg path {
                fill: var(--color-text-p-dm) !important;
            }
    
        }
    
        &.outline {
            background-color: transparent;
            outline-style: none !important;
    
            color: var(--color-theme-p);
    
            &:hover {
                background-color: var(--color-theme-p-hover);
            }

            svg path {
                fill: var(--color-theme-p) !important;
            }
    
        }
    
        &.status {
            border-color: var(--color-status-p) !important;
            background-color: var(--color-status-p) !important;

            &:hover {
                background-color: var(--color-status-t) !important;
            }
    
            &.disabled {
                color: var(--color-text-s-lm) !important;
                
                pointer-events: none;
            }
    
        }
    
        .spinner {
            width: 20px;
            height: 20px;
            border: 3px solid var(--color-text-p-dm);
            border-right-color: transparent;
            border-radius: 50%;
    
            -webkit-animation: spinnerAnimation 0.75s linear infinite;
            animation: spinnerAnimation 0.75s linear infinite;
        }
    
    }

}

.dark-mode .btnWrapper {

    .btn {

        &.outline {
            border-color: var(--color-text-p) !important;

            color: var(--color-text-p) !important;

            svg path {
                fill: var(--color-text-p-dm) !important;
            }

        }

        &.status {
            border-color: var(--color-status-p) !important;
            background-color: var(--color-status-p) !important;

            &.disabled svg path {
                fill: var(--color-status-p) !important;
            }

            &:hover {
                background-color: var(--color-status-t) !important;
            }

        }

    }

}

@keyframes spinnerAnimation {
    0% {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}