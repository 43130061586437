.modalWrapper {

    .containerWrapper {
        
        &:is(.mb-6) {
            flex-wrap: wrap;
            gap: 16px 0;
            
            .textContainerWrapper {
                width: 50% !important;
            }

        }

    }

}