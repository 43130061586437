.tabWrapper {
    width: 100%;

    .tabControlContainer {
        display: flex;
        flex-direction: row;
        gap: 24px;
        width: 100%;
        margin-bottom: 24px;

        .tabControl {
            padding: 12px 24px;
            border-radius: 8px;
            background-color: var(--color-disabled-s);

            color: var(--color-text-s);
            font-size: 14px;

            cursor: pointer;
            transition: var(--transition-theme);

            &:hover {
                background-color: var(--color-disabled-p);
            
                color: var(--color-text-p);
            }

            &.active {
                background-color: var(--color-theme-p);

                color: var(--color-text-p-dm);

                &:hover {
                    background-color: var(--color-theme-p-dark);
                }

            }

        }

    }

}