.textContainerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.status {

        .iconContainer svg path {
            color: var(--color-status-p) !important;
        }

    }

    .iconContainer {
        margin-right: 16px;

        svg path {
            color: var(--color-theme-p);
        }

    }

    .textContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    
        &.focusOnText {
    
            .title {
                color: var(--color-text-t) !important;
            }
            
            .text {
                color: var(--color-text-p) !important;
            }
    
    
        }
    
        span {
            text-align: center;
    
            transition: var(--transition-theme);
        }
    
        .title {
            color: var(--color-text-p);
        }
        
        .text {
            color: var(--color-text-t);
        }
    
    }

}


.dark-mode {

    .textContainerWrapper {

        &:not(.status){

            .iconContainer {
        
                svg path {
                    color: var(--color-text-p);
                }
        
            }

        }

    }

}