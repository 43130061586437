.loaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.loader {
    width: 24px;
    height: 24px;
    border: 3px solid var(--color-text-p);
    border-right-color: transparent;
    border-radius: 50%;

    -webkit-animation: spinnerAnimation 0.75s linear infinite;
    animation: spinnerAnimation 0.75s linear infinite;
}

@keyframes loaderAnimation {
    0% {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}